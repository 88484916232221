
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'BorrowerChooseLoan',
  components: { DefaultLayout, BaseButton, IconCheckCircle },
  setup() {
    const router = useRouter();

    const handleClickEasyLoan = () => {
      router.push({
        name: constRouter.BORROWER_LOAN.name,
        params: { type: 'easyLoan' },
      });
    };
    const handleClickPersonalLoan = () => {
      router.push({
        name: constRouter.BORROWER_LOAN.name,
        params: { type: 'personalLoan' },
      });
    };

    return {
      taskEmphasize: [true, false],
      vh: window.innerHeight * 0.01,
      handleClickPersonalLoan,
      handleClickEasyLoan,
    };
  },
  data: () => ({
    isEnableEasyloan: false,
    isEnablePersonal: false,
  }),
  created() {
    if (process.env.VUE_APP_ENABLE_EASY === 'true') {
      this.isEnableEasyloan = true;
    }
    if (process.env.VUE_APP_ENABLE_PERSONAL === 'true') {
      this.isEnablePersonal = true;
    }

    // set color card
    if (this.isEnableEasyloan === false) {
      this.taskEmphasize[1] = true;
    }
  },
});
