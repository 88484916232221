<template>
  <default-layout>
    <div class="background-img">
      <div class="mx-6 mt-6">
        <h2 class="text-2xl text-center">
          Nhu cầu ứng của bạn <br />
          đã được ghi nhận
        </h2>
        <p class="mt-2">
          - Với thông tin hiện tại, Interloan không thể đáp ứng nhu cầu ứng
          lương của bạn
        </p>
        <p class="">
          - Interloan xin giới thiệu các sản phẩm được hỗ trợ bởi Công ty Tài
          chính <strong>Shinhan Finance Việt Nam</strong>
        </p>
      </div>
      <div class="lender py-6 mx-6 flex justify-center flex-col">
        <div
          v-if="isEnableEasyloan"
          @click="handleClickEasyLoan"
          class="cursor-pointer"
        >
          <div
            class="invest-item  p-3 shadow-3"
            :class="{
              emphasize: taskEmphasize[0],
            }"
          >
            <div class="invest-title ">
              <base-button
                to="#"
                class="normal-case px-4 py-2 border-16"
                :class="{
                  'bg-tertiary text-white': taskEmphasize[0],
                  'bg-transparent bg-none border-1 border-primary shadow-none': !taskEmphasize[0],
                }"
              >
                Vay nhanh
              </base-button>
            </div>
            <div class="invest-content">
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Nhận tiền trong 1 ngày.</div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Hỗ trợ ứng 15 hoặc 20 triệu đồng.</div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  Cần cung cấp thêm thông tin địa chỉ.
                </div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  <b>Cần cung cấp thêm ảnh Sổ Hộ Khẩu.</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isEnablePersonal"
          @click="handleClickPersonalLoan"
          class="cursor-pointer"
        >
          <div
            class="shadow-1 invest-item  p-3 mt-6"
            :class="{
              emphasize: taskEmphasize[1],
            }"
          >
            <div class="invest-title ">
              <base-button
                to="#"
                class="normal-case px-4 py-2 border-16"
                :class="{
                  'bg-tertiary text-white': taskEmphasize[1],
                  'bg-transparent bg-none border-1 border-primary shadow-none': !taskEmphasize[1],
                }"
              >
                Vay tín chấp
              </base-button>
            </div>
            <div class="invest-content">
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  <b>Không cần bổ sung thêm thông tin.</b>
                </div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  <b>Hỗ trợ ứng lên đến 300 triệu đồng.</b>
                </div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Nhận tiền trong vòng 2 ngày.</div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Sẽ có nhân viên CSKH liên hệ.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'BorrowerChooseLoan',
  components: { DefaultLayout, BaseButton, IconCheckCircle },
  setup() {
    const router = useRouter();

    const handleClickEasyLoan = () => {
      router.push({
        name: constRouter.BORROWER_LOAN.name,
        params: { type: 'easyLoan' },
      });
    };
    const handleClickPersonalLoan = () => {
      router.push({
        name: constRouter.BORROWER_LOAN.name,
        params: { type: 'personalLoan' },
      });
    };

    return {
      taskEmphasize: [true, false],
      vh: window.innerHeight * 0.01,
      handleClickPersonalLoan,
      handleClickEasyLoan,
    };
  },
  data: () => ({
    isEnableEasyloan: false,
    isEnablePersonal: false,
  }),
  created() {
    if (process.env.VUE_APP_ENABLE_EASY === 'true') {
      this.isEnableEasyloan = true;
    }
    if (process.env.VUE_APP_ENABLE_PERSONAL === 'true') {
      this.isEnablePersonal = true;
    }

    // set color card
    if (this.isEnableEasyloan === false) {
      this.taskEmphasize[1] = true;
    }
  },
});
</script>
<style lang="scss" scoped>
$color: #f1b45b;

.background-img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lender {
  @media (max-width: 370px) {
    height: unset;
  }

  .invest-item {
    background: white;
    border-left: 5px solid;
    border-radius: 4px;

    .invest-title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 370px) {
        flex-direction: column !important;
        .invest-title-interest-rate {
          position: unset !important;
        }
      }

      span {
        b {
          font-size: 36px;
        }
      }

      span {
        position: relative;

        .invest-title-interest-rate {
          left: 0;
          right: unset;
        }
      }

      .invest-title-interest-rate {
        position: absolute;
        top: -10px;
        right: 0;
      }
    }

    .invest-content {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
    }

    &.emphasize {
      border-left-color: $color;
      left: 0;
      right: unset;

      .invest-title {
        > span {
          position: relative;

          .invest-title-interest-rate {
            left: 0;
            right: unset;
          }

          b {
            color: $color;
          }
        }
      }
    }

    &.align-invert {
      border-left: none;
      border-right: 5px solid $color;

      .invest-title {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
